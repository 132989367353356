<template>
  <div class="fineArts width_1200">
    <el-carousel class="" trigger="click" height="300px">
      <el-carousel-item v-for="item in banners" :key="item.Id">
        <img
          v-if="!item.video"
          v-lazy="item.src"
          :alt="item.Name"
          height="300"
          width="100%"
        />
        <video
          v-else
          height="300"
          width="100%"
          :src="item.src"
          controls
        ></video>
      </el-carousel-item>
    </el-carousel>
    <div class="fineArts_list fineArts_list1 bgc_ffffff">
      <div class="fineArts_title">
        <span class="name">专家团队</span>
        <div class="more">
          <router-link
            class="li"
            :to="{
              path: '/teacher/index',
              query: {
                type: 2,
              },
            }"
          >
            查看更多<i class="el-icon-arrow-right"></i>
          </router-link>
        </div>
      </div>
      <ul>
        <li v-for="(item, i) in zjtdList" :key="i">
          <router-link
            class="li"
            :to="{
              path: '/teacher/teacherDetails',
              query: { id: item.Id, type: 2 },
            }"
          >
            <img v-lazy="item.HeadImg" alt="" srcset="" />
            <div class="details">
              <div class="name">{{ item.ArtistName }}</div>
              <div class="intr decimalPoint-2">{{ item.BriefIntroduce }}</div>
            </div>
          </router-link>
        </li>
      </ul>
    </div>
    <div class="fineArts_list fineArts_list2 bgc_ffffff">
      <div class="left">
        <div class="fineArts_title">
          <span class="name">{{ list0.CategoryName }}</span>
          <div class="more">
            <router-link
              class="li"
              :to="'/informationList/index?type=' + list0.Id"
            >
              查看更多<i class="el-icon-arrow-right"></i>
            </router-link>
          </div>
        </div>
        <ul>
          <li v-for="(item, i) in list0.data" :key="i" @click="handleTo(item)">
            <img v-lazy="item.CoverImg" alt="" srcset="" />
            <div class="details">
              <div class="name decimalPoint-2">{{ item.Title }}</div>
              <div class="intr">{{ item.CreateTime | dataYMD }}</div>
            </div>
          </li>
        </ul>
      </div>
      <div class="right">
        <div class="fineArts_title">
          <span class="name">{{ list1.CategoryName }}</span>
          <div class="more">
            <router-link
              class="li"
              :to="'/informationList/index?type=' + list1.Id"
            >
              查看更多<i class="el-icon-arrow-right"></i>
            </router-link>
          </div>
        </div>
        <ul>
          <li v-for="(item, i) in list1.data" :key="i" @click="handleTo(item)">
            <img v-lazy="item.CoverImg" alt="" srcset="" />
            <div class="details">
              <div class="name decimalPoint-2">{{ item.Title }}</div>
              <div class="intr">{{ item.CreateTime | dataYMD }}</div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="fineArts_list3">
      <div class="left">
        <ul class="fineArts_list bgc_ffffff">
          <div class="fineArts_title">
            <span class="name">{{ list2.CategoryName }}</span>
            <div class="more">
              <router-link
                class="li"
                :to="'/informationList/index?type=' + list2.Id"
              >
                查看更多<i class="el-icon-arrow-right"></i>
              </router-link>
            </div>
          </div>
          <li v-for="(item, i) in list2.data" :key="i" @click="handleTo(item)">
            <div class="details">
              <img v-lazy="item.CoverImg" alt="" srcset="" />
              <div class="intr decimalPoint-1">{{ item.Title }}</div>
            </div>
          </li>
        </ul>
        <ul class="fineArts_list bgc_ffffff">
          <div class="fineArts_title">
            <span class="name">{{ list3.CategoryName }}</span>
            <div class="more">
              <router-link
                class="li"
                :to="'/informationList/index?type=' + list3.Id"
              >
                查看更多<i class="el-icon-arrow-right"></i>
              </router-link>
            </div>
          </div>
          <li v-for="(item, i) in list3.data" :key="i" @click="handleTo(item)">
            <div class="details">
              <img v-lazy="item.CoverImg" alt="" srcset="" />
              <div class="intr decimalPoint-1">{{ item.Title }}</div>
            </div>
          </li>
        </ul>
      </div>
      <div class="right">
        <ul class="fineArts_list bgc_ffffff">
          <div class="fineArts_title">
            <span class="name">{{ list4.CategoryName }}</span>
            <div class="more">
              <router-link
                class="li"
                :to="'/informationList/index?type=' + list4.Id"
              >
                查看更多<i class="el-icon-arrow-right"></i>
              </router-link>
            </div>
          </div>
          <li v-for="(item, i) in list4.data" :key="i" @click="handleTo(item)">
            <img v-lazy="item.CoverImg" alt="" srcset="" />
            <div class="details">
              <div class="name decimalPoint-2">{{ item.Title }}</div>
              <div class="intr">{{ item.CreateTime | dataYMD }}</div>
            </div>
          </li>
        </ul>
        <div class="fineArts_details">
          <div class="intr">
            <h5>三合美术馆</h5>
            <p>
              普利美术馆是一座以收藏、研究、推广、展示
              近现代及当代艺术家作品为重点，并致力于审
              美教育与国际间文化交流的综合造型艺术博物 馆。
            </p>
            <router-link :to="'/fineArts/details'">
              <div class="c_join">详情</div>
            </router-link>
          </div>
        </div>
        <div class="fineArts_details cooperation">
          <div class="intr">
            <h5>商务合作</h5>
            <div class="c_join" @click="ewmClick">加入</div>
          </div>
        </div>
      </div>
    </div>

    <el-dialog
      title="在线客服"
      :visible.sync="ewm"
      width="550px"
      :before-close="handleCloseEwm"
      center
    >
      <div class="service">
        <div v-html="kefu"></div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { updateNewsReadCount, queryBannerList, queryArtist, queryArtGalleryCategory, queryArtGalleryNews } from "@/api/fineArts";
import { queryOnlineService } from "@/api/shop";
export default {
  name: "index",
  computed: {},
  watch: {},
  data () {
    return {
      search: "",
      banners: [],
      zjtdList: [],
      list0: { data: [] },
      list1: { data: [] },
      list2: { data: [] },
      list3: { data: [] },
      list4: { data: [] },
      artList: [],
      pageSize: 10,
      pageIndex: 1,
      total: 0,
      ewm: false,
      kefu: ""
    };
  },
  created () {
    this.init()
    this.QueryOnlineService()
  },
  mounted () {
  },
  beforeCreate () {
  },
  beforeDestroy () {
  },
  methods: {
    handleTo (obj) {
      if (obj.JumpUrl) {
        this.postUpdateNewsReadCount(obj.Id)
        window.open(obj.JumpUrl)
      } else {
        let path = "/informationList/details";
        let query = {
          id: obj.Id,
        };
        this.$router.push({ path, query });
      }
    },
    //浏览量自动+1
    async postUpdateNewsReadCount (id) {
      let parm = "?Id=" + id
      const res = await updateNewsReadCount(parm);
    },
    async init () {
      let parm = "?category=4"
      const res = await queryBannerList(parm);
      if (res.success == true) {
        res.response.forEach(element => {
          var flieArr = element.ImgUrl.split('.')
          var suffix = flieArr[flieArr.length - 1]
          var videolist = ['mp4', 'm2v', 'mkv']
          var result = videolist.some((item) => {
            return item == suffix
          })
          this.banners.push({
            video: result,
            msrc: element.ImgUrl,
            src: element.ImgUrl,
            h: 375
          })
        });
      }
      let parm1 = {
        pageIndex: 1,
        pageSize: 3,
      }
      const res1 = await queryArtist(parm1);
      if (res1.success == true) {
        this.zjtdList = res1.response.data;
      }
      const res2 = await queryArtGalleryCategory(0);
      if (res2.success == true) {
        res2.response.forEach((element, index) => {
          if (index <= 4) {
            this.postqueryArtGalleryNews(element, index);
          }
        });
      }
    },
    async postqueryArtGalleryNews (obj, index) {
      let size = 3
      if (index == 2 || index == 3) {
        size = 1
      }
      let parm = {
        categoryId: obj.Id,
        type: 1,
        pageIndex: 1,
        pageSize: size,
      }
      const res = await queryArtGalleryNews(parm);
      if (res.success == true) {
        if (index == 0) {
          this.list0 = obj
          this.$set(this.list0, "data", res.response.data)
        }
        if (index == 1) {
          this.list1 = obj
          this.$set(this.list1, "data", res.response.data)
        }
        if (index == 2) {
          this.list2 = obj
          this.$set(this.list2, "data", res.response.data)
        }
        if (index == 3) {
          this.list3 = obj
          this.$set(this.list3, "data", res.response.data)
        }
        if (index == 4) {
          this.list4 = obj
          this.$set(this.list4, "data", res.response.data)
        }
      }
    },
    ewmClick () {
      this.ewm = true;
    },
    handleCloseEwm () {
      this.ewm = false;
    },
    //在线客服
    async QueryOnlineService () {
      const res = await queryOnlineService()
      this.kefu = res.response.OnlineService
    },
  },
};
</script>
<style lang="less" scoped>
.service {
  // position: absolute;
  z-index: 9999;
  width: 300px;
  height: 300px;
  // left: 43%;
  // top: 30%;
  margin: 0 auto;
  /deep/img {
    width: 100%;
  }
}
.fineArts {
  .el-carousel {
    margin: 30px 0 40px;
  }
  .fineArts_title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #aaa8a8;
    line-height: 1;
    padding-bottom: 16px;
    border-bottom: 1px solid #e6e6e6;
    .name {
      font-weight: bold;
      font-size: 20px;
      color: #333333;
    }
    .more {
      cursor: pointer;
    }
  }
  .fineArts_list {
    padding: 20px 30px;
  }
  .fineArts_list1 {
    box-shadow: 4px 4px 10px rgba(90, 90, 90, 0.16);
    ul {
      display: flex;
      margin: 30px 0 10px;
      li {
        margin-left: 50px;
        flex: 0 0 350px;
        .li {
          display: flex;
          &:first-child {
            margin-left: 0;
          }
          img {
            width: 80px;
            height: 80px;
            border-radius: 50%;
          }
          .details {
            margin-left: 15px;
            flex: 0 0 200px;
            .name {
              font-size: 16px;
              font-weight: 800;
            }
            .intr {
              margin-top: 15px;
              color: #6b666b;
              line-height: 1.5;
            }
          }
        }
      }
    }
  }

  .fineArts_list2 {
    display: flex;
    justify-content: space-between;
    margin: 30px 0;
    box-shadow: 4px 4px 10px rgba(90, 90, 90, 0.16);
    .left,
    .right {
      flex: 0 0 545px;
      width: 545px;
    }
    ul {
      li {
        display: flex;
        margin-right: 20px;
        padding: 20px 0;
        border-bottom: 1px solid #e6e6e6;
        &:last-child {
          border-bottom: none;
        }
        img {
          width: 208px;
          height: 120px;
          flex: 0 0 208px;
        }
        .details {
          margin-left: 15px;
          flex: 0 0 294px;
          display: flex;
          flex-wrap: wrap;
          // align-content: space-between;
          .name {
            flex: 0 0 100%;
            font-weight: bold;
            font-size: 18px;
          }
          .intr {
            flex: 0 0 100%;
            font-size: 16px;
            color: #999999;
          }
        }
      }
    }
  }
  .fineArts_list3 {
    display: flex;
    justify-content: space-between;
    margin: 30px 0;
    .left {
      flex: 0 0 656px;
      ul {
        margin-bottom: 30px;
        box-shadow: 4px 4px 10px rgba(90, 90, 90, 0.16);
        li {
          .details {
            position: relative;
            width: 100%;
            height: 335px;
            img {
              width: 100%;
              height: 100%;
            }
            /deep/.intr {
              width: 100%;
              line-height: 38px;
              font-size: 16px;
              color: #ffffff;
              padding: 0 16px;
              position: absolute;
              left: 0;
              bottom: 0;
              background-color: rgba(0, 0, 0, 0.5);
              p {
                color: #ffffff;
              }
            }
          }
        }
      }
    }
    .right {
      flex: 0 0 510px;
      ul {
        margin-bottom: 30px;
        box-shadow: 4px 4px 10px rgba(90, 90, 90, 0.16);
        li {
          display: flex;
          margin-right: 20px;
          padding: 20px 0;
          border-bottom: 1px solid #e6e6e6;
          &:last-child {
            border-bottom: none;
          }
          img {
            width: 208px;
            height: 120px;
            flex: 0 0 208px;
          }
          .details {
            margin-left: 15px;
            flex: 0 0 222px;
            display: flex;
            flex-wrap: wrap;
            // align-content: space-between;
            .name {
              flex: 0 0 100%;
              font-weight: bold;
              font-size: 18px;
            }
            .intr {
              flex: 0 0 100%;
              font-size: 16px;
              color: #999999;
            }
          }
        }
      }
      .fineArts_details {
        height: 260px;
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: center;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url("../../assets/images/bg2@2x.png");
        margin: 30px 0;
        .intr {
          padding: 30px;
          text-align: center;
          h5 {
            font-size: 26px;
            font-weight: 800;
            margin-bottom: 20px;
          }
          p {
            font-size: 13px;
            line-height: 1.5;
            text-align: left;
          }
          .c_join {
            display: inline-block;
            font-size: 19px;
            color: #ffffff;
            width: 100px;
            height: 44px;
            line-height: 44px;
            background: #3a78f9;
            border-radius: 30px;
            text-align: center;
            margin: 0 auto;
            margin-top: 15px;
            cursor: pointer;
            font-weight: normal;
            a {
              font-size: 19px;
            }
          }
        }
      }
      .cooperation {
        height: 146px;
        background-image: url("../../assets/images/bg_cooperation.png");
        .c_join {
          margin-top: 0;
        }
      }
    }
  }
}
</style>
